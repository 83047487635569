/**
 *  Abbreviate filter - Takes a number and returns the abbreviated form.
 *  Can also be rounded to the nearest nth decimal place.
 *
 *  E.g.
 *  1,000 | abbreviate ==> '1K'
 *  1,000,000 | abbreviate ==> '1M'
 *  1,000,000,000 | abbreviate ==> '1B'
 *  1,000,000,000,000 | abbreviate ==> '1T'
 *
 */
window.angular.module('MyHippoProducer.Filters', []).filter('abbreviate', function () {
    // https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k/2686098#2686098
    return (number, decPlaces) => {
        decPlaces = Math.pow(10, decPlaces || 0);
        const abbrev = [ 'K', 'M', 'B', 'T' ];

        for (let i = abbrev.length - 1; i >= 0; i--) {
            const size = Math.pow(10, (i + 1) * 3);

            if (size <= number) {
                number = Math.round(number * decPlaces / size) / decPlaces;

                if ((number === 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                number += abbrev[i];
                break;
            }
        }
        return number;
    };
});
